import React from 'react'
import '../styles/header.scss'
import Layout from "../components/layout";

const ScreenRecorder = () => (
    <Layout>
    <p>screen-recorder</p>
    </Layout>
);

export default ScreenRecorder
